<template>
    <styled-tooltip
        position="top"
        :max-width="500">
        {{ value }}

        <template #content>
            <p>{{ details.header.calculatedToolTip.title }}</p>

            <div class="budgeting-equation">
                <div v-if="details.header.calculatedToolTip.details[0].item">
                    <div>{{ getItemValue(0) }}</div>

                    <div>{{ getTitleCase(details.header.calculatedToolTip.details[0].item) }}</div>
                </div>

                <div
                    v-if="details.header.calculatedToolTip.details[0].item"
                    class="budgeting-equation-symbol">
                    &divide;
                </div>

                <div class="budgeting-equation-brace">
                    (
                </div>

                <div>
                    <div>{{ getItemValue(1) }}</div>
                    <div>{{ getTitleCase(details.header.calculatedToolTip.details[1].item) }}</div>
                </div>

                <div class="budgeting-equation-symbol">
                    &divide;
                </div>

                <div>
                    <div>{{ getItemValue(2) }}</div>
                    <div>{{ getTitleCase(details.header.calculatedToolTip.details[2].item) }}</div>
                </div>

                <div class="budgeting-equation-brace">
                    )
                </div>
            </div>
        </template>
    </styled-tooltip>
</template>

<script>
import StyledTooltip from '@/components/globals/StyledTooltip.vue';
import numeral from 'numeral';
import getNumberFormats from '@/helpers/numberFormats';

const totalsNumberFormats = getNumberFormats(true);

export default {
    components: {
        StyledTooltip,
    },
    props: {
        value: {
            type: String,
            required: true,
        },
        details: {
            type: Object,
            required: true,
        },
    },
    methods: {
        getItemValue(index) {
            const key = this.details.header.calculatedToolTip.details[index].item;
            const formatKey = key + '_format';
            const format = this.details.totalObject[formatKey];
            let value = this.details.header.calculatedToolTip.details[index].value;

            if (value) {
                return value;
            }

            value = Math.round(this.details.totalObject[key]).toFixed(2);

            return numeral(value).format(totalsNumberFormats[format]);
        },
        getTitleCase(text) {
            return text
                .split('_')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                .join(' ');
        }
    }
};
</script>
