<template functional>
    <td
        :class="[
            data.class,
            data.staticClass
        ]">
        <template v-if="props.isNull">
            <span class="empty-field">|</span>
        </template>
        <template v-else>
            <slot />
        </template>
    </td>
</template>

<script>
export default {
    props: {
        isNull: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>

.empty-field {
    transform: rotate(38deg);
    display: block;
    font-size: 17px;
    font-weight: 300;
    color: $gray-light;
}
</style>
