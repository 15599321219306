<template>
    <div>
        <span
            v-if="showTextOnly && !showIconOnly"
            v-clipboard:copy="value"
            v-clipboard:success="onCopy"
            class="copy-text">
            <template v-if="$slots['default']">
                <slot />
            </template>
            <template v-else>
                {{ value }}
            </template>
        </span>
        <v-btn
            v-if="showIconOnly || showIcon"
            v-clipboard:copy="value"
            v-clipboard:success="onCopy"
            text
            icon
            small>
            <Icon
                name="copy"
                size="16px">
                />
            </icon>
        </v-btn>
    </div>
</template>
<script>
import Icon from '@/components/globals/Icon';

export default {
    components: {
        Icon
    },
    props: {
        value: {
            type: [String, Number],
            required: true
        },
        showTextOnly: {
            type:Boolean,
            required:false,
            default:true,
        },
        showIconOnly:{
            type:Boolean,
            required:false,
            default:false,
        },
        showIcon:{
            type:Boolean,
            required:false,
            default:false,
        },
    },
    data() {
        return {};
    },
    methods: {
        onCopy(e) {
            this.$flash(`${e.text} copied to clipboard!`, 'green');
        }
    }
};
</script>
<style scoped>
.copy-text:hover {
    opacity: 0.8;
    cursor: pointer;
}
</style>
