<template>
    <div v-show="showLoader">
        <v-progress-linear
            height="10"
            :value="loadingProgress"
            striped />
        <div class="text-xs-center">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            required: true
        },
        gain: {
            type: Number,
            default: 0.5
        },
        topEnd: {
            type: Number,
            default: 90
        }
    },
    data() {
        return {
            showLoader: false,
            loadingProgress: 0
        };
    },
    watch: {
        value(newValue, oldValue) {

            // We're loading
            if (oldValue === false && newValue === true) {
                this.startLoading();
            }

            // We stopped loading
            else if (oldValue === true && newValue === false) {
                this.stopLoading();
            }
        }
    },
    created() {
        this.loadingTimer = null;

        // If we're loading on mount
        if (this.value === true) {
            this.startLoading();
        }
    },
    destroyed() {
        clearInterval(this.loadingTimer);
    },
    methods: {
        startLoading() {
            // Start at 0
            this.showLoader = true;
            this.loadingProgress = 0;

            // Just in case there's an existing timer running
            if (this.loadingTimer) {
                clearInterval(this.loadingTimer);
            }

            // Every 50ms add the gain to the loading progress indicator
            this.loadingTimer = setInterval(() => {
                // Don't ever display this over the top end (default 90) since it
                // will break and give the impression that we're loaded
                if (this.loadingProgress < this.topEnd) {
                    this.loadingProgress += this.gain;
                }
            }, 50);
        },
        stopLoading() {
            clearInterval(this.loadingTimer);

            this.loadingProgress = 100;
            // Add a small timeout so the user sees the loader go to 100
            setTimeout(() => {
                // Once loading stops just consider that we're at 100%
                this.showLoader = false;

                // Tell the parent that the process is done
                this.$emit('complete');
            }, 800);
        }
    }
};
</script>
