import HTTP from '@/http';
import { chunk } from 'lodash';

/**
 * Retrieves the total daily budget given an array of campaigns
 *
 * @param {Array} campaigns
 * @returns {Number}
 */
const getTotalDailyBudget = (campaigns) => {
    let totalActiveDailyBudget = null;
    campaigns.forEach(campaign => {

        // Only active campaigns
        if (campaign.effective_status !== 'ACTIVE' || !campaign.daily_budget) {
            return;
        }

        const dailyBudget = parseInt(campaign.daily_budget);

        // If it's zero don't add it up - this way we can leave totalActiveDailyBudget
        // as null if no campaigns are running
        if (dailyBudget === 0) {
            return;
        }

        totalActiveDailyBudget += dailyBudget / 100; // Facebook sends whole numbers
    });
    return totalActiveDailyBudget;
};

const getAdAccounts = async(agencyId, params, groupsIds) => {

    let allData = [];

    const makeRequest = async(agencyId, params, after = null) => {
        if (after) {
            params.after = after;
        }
        const groupIds = groupsIds ? `?groupIds=${groupsIds}` : '';
        const ownedAdAccountsResponse = await (new HTTP()).post(`/agencies/${agencyId}/graph-api/business-manager/owned_ad_accounts${groupIds}`, params);
        const clientAdAccountsResponse = await (new HTTP()).post(`/agencies/${agencyId}/graph-api/business-manager/client_ad_accounts${groupIds}`, params);

        const newData = ownedAdAccountsResponse.data?.data || [];
        const newDataClient = clientAdAccountsResponse.data?.data || [];

        allData = newData.concat(newDataClient);
        // We have to check next but use the cursor.  This is an artifact
        // of the way the proxy API works and the fact that we had to
        // change to a POST because of data limits on GET requests
        if (ownedAdAccountsResponse.data?.paging?.next) {
            await makeRequest(agencyId, params, ownedAdAccountsResponse.data?.paging?.cursors?.after);
        }
        if (clientAdAccountsResponse.data?.paging?.next) {
            await makeRequest(agencyId, params, clientAdAccountsResponse.data?.paging?.cursors?.after);
        }
    };

    await makeRequest(agencyId, params);

    return allData;
};

const getAgencyInsightsChunk = async(config) => {

    // Default fields
    const fields = [
        'account_id',
        'campaign_id',
        'campaign_name',
        'account_name',
        'clicks',
        'spend',
        'reach',
        'cpc',
        'cpm',
        'cpp',
        'ctr',
        'frequency',
        'impressions',
        'unique_clicks',
        'unique_ctr',
        'unique_inline_link_click_ctr',
        'unique_inline_link_clicks',
        'unique_link_clicks_ctr',
        'inline_link_click_ctr',
        'inline_link_clicks',
        'cost_per_action_type',
        'cost_per_unique_inline_link_click',
        'cost_per_unique_click',
        'cost_per_unique_outbound_click',
        'cost_per_thruplay',
        'cost_per_outbound_click',
        'cost_per_unique_action_type',
        'video_play_actions',
        'website_ctr',
        'unique_outbound_clicks_ctr',
        'unique_outbound_clicks',
        'unique_actions',
        'outbound_clicks_ctr',
        'outbound_clicks',
        'actions',
        'action_values',
        'conversion_values',
        'catalog_segment_value',
        'converted_product_quantity',
        'converted_product_value',
        'cost_per_conversion',
        'instant_experience_outbound_clicks',
        'mobile_app_purchase_roas',
        'purchase_roas',
        'website_purchase_roas'
    ];

    // Set defaults
    config = {
        ...{
            dealerId: null,
            campaigns: [],
            startDate: null,
            endDate: null,
            filtering: null,
            insightsFiltering: [],
            campaignFiltering: [],
            attributionWindows: [],
            fields
        },
        ...config
    };

    const params = {
        fields: `name,status,insights.action_attribution_windows(${JSON.stringify(config.attributionWindows)}).time_range({"since":"${config.startDate}","until":"${config.endDate}"}){${config.fields.join(',')}}`,
    };

    if (config.limit) {
        params.limit = config.limit;
    }

    if (config.filtering) {
        params.filtering = config.filtering;
    }

    const data = await getAdAccounts(config.agencyId, params, config.groupsIds);

    const dataFormatted = data.map(account => {

        // Campaigns
        account.campaigns = account.campaigns?.data || [];

        // With this type of query there's only ever 1 insights node
        account.insights = account.insights?.data?.[0] || {};

        // Reformat metrics
        account.dealer_budget = account.dealer_budget ? parseFloat(account.dealer_budget) : null;
        account.id = account.id.replace('act_', '');
        account.daily_budget = getTotalDailyBudget(account.campaigns);

        return account;
    });

    return dataFormatted;
};

const getAgencyInsights = async(config) => {

    const { accountIds, chunkSize = 50 } = config;

    const chunks = chunk(accountIds, chunkSize);

    // Retrieve all chunks from Facebook at once
    const results = await Promise.all(chunks.map(chunk => {

        config.filtering = [{
            field: 'id',
            operator: 'IN',
            value: chunk
        }];

        return getAgencyInsightsChunk(config);
    }));

    const data = results.flat();

    return data;
};

export default getAgencyInsights;